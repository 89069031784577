import React, { useEffect } from "react";
import "./Shuttlecocks.scss";

const Shuttlecocks = () => {
    return (
        <div className="containerBall">
            <div id="ball"></div>
            <div id="ball2"></div>
        </div>
    );
};

export default Shuttlecocks;